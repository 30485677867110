import React from "react"
import { useLoader } from '@react-three/fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import Pilar from "./FlamePilar"

export default function Studio() {
    const trybuns = useLoader(GLTFLoader, 'resources/Studio.gltf')

    const element = trybuns.scene;
    element.position.set(-15, -1, 4);
    element.scale.set(5, 5, 5);
    element.receiveShadow = true;
    window.trybuns = trybuns;

    return (
        <group>
            <primitive object={trybuns.scene} />
            <Pilar position={[45, 14.5, 10]} rotationY={0}/>
            <Pilar position={[42, 14.5, 30]} rotationY={-0.35} />
            <Pilar position={[32, 14.5, 47]} rotationY={-0.75} />
            <Pilar position={[12, 14.5, 57]} rotationY={-1.75} />
        </group>
        
    )
}