import io from 'socket.io-client';
// import io from "socket.io-client/dist/socket.io.js"
// import {SERVER_URL} from '@env';
const SERVER_URL = "https://challenge2.hxspace.pl";

let socket = io(SERVER_URL);

function init() {
  socket = io(SERVER_URL);
}

function passSocket() {
  return socket;
}

export {passSocket, init, io};