import style from "./Answer.module.css"

export default function Answer({ color, value }) {

    function setColor() {
        switch (color) {
            case "red":
                return style.colorRed;
                break;
            case "green":
                return style.colorGreen;
                break;
            case "blue":
                return style.colorBlue;
                break;
            case "yellow":
                return style.colorYellow;
                break;
        }
    }

    return (
        <div className={ style.container+" "+setColor() }>
            <div style={{marginLeft: "10%", marginRight: "10%"}}>
               {value?.content}
            </div>
        </div>
    )
}