import { useState, useRef } from "react"
import * as THREE from "three";

import url from "../../assets/flames.mp4";

const Pilar = ({ position, rotationY }) => {

    const FlamesBox = useRef();

    const [flames] = useState(() => {
        const vid = document.createElement("video");
        vid.src = url;
        vid.crossOrigin = "Anonymous";
        vid.loop = true;
        vid.muted = true;
        vid.play();
        return vid;
    });

    return (
      <group ref={FlamesBox} position={position} scale={[6, 25, 8]} rotation={[0, rotationY, 0]}>
        <mesh>
          <boxGeometry attach="geometry" />
          <meshStandardMaterial emissive={"white"} side={THREE.DoubleSide} >
            <videoTexture attach="map" args={[flames]} />
            <videoTexture attach="emissiveMap" args={[flames]} />
          </meshStandardMaterial>
        </mesh>
      </group>
    );
  };

  export default Pilar;