/*
This file was generated by https://github.com/pmndrs/gltfjsx and then
customized manually. It uses drei's new useAnimations hook which extracts
all actions and sets up a THREE.AnimationMixer for it so that you don't have to.
All of the assets actions, action-names and clips are available in its output. 
*/

import React, { useEffect, useState, useRef } from "react"
import { useLoader, useFrame } from '@react-three/fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import * as THREE from "three";

export default function Man() {
  const gltf = useLoader(GLTFLoader, '/Showmaker.gltf')

  const boxRef = useRef();
  const [active, setActive] = useState(false);
  const [hover, setHover] = useState(false);

  useFrame(() => {
    if(hover) {
      boxRef.current.rotation.y += 0.05;
      console.log("ELO")
    }
  })


  let model = gltf.scene;
  model.position.set(14, 5, -26);
  model.scale.set(5, 5, 5);
  model.rotation.y = -1;

  let mixer;
  if (gltf.animations.length) {
      mixer = new THREE.AnimationMixer(gltf.scene);
      gltf.animations.forEach(clip => {
          const action = mixer.clipAction(clip)
          action.play();
      });
  }

    useFrame((state, delta) => {
      mixer?.update(delta)
  })

  return (
      <primitive
          ref={boxRef}
          onClick={() => {
            setActive(!active);
          }}

          onPointerOver={() => {
            console.log(("HOQ"))
            setHover(true);
          }}
          onPointerOut={() => {
            setHover(false);
          }}

          object={gltf.scene} 
          onClick={() => { console.log("CLIECK")}}
       />
  )
}