import React, {useState} from "react";
import style from "./WaitingForStart.module.css"
import Spinner from 'react-bootstrap/Spinner'
import { Default, Heart } from 'react-spinners-css';


export default function WaitingForStart({code}) {
    const [loading, setLoading] = useState(true);
    return (
        <div className={style.container}>
            <div className={style.loadingContainer}>
                <div className={style.loaderContent}>
                     <Default className={style.loader} color="red" size={200}/>
                </div>
                <div className={style.textLoading}>
                    Oczekiwanie na rozpoczęcie rozgrywki...
                </div>
                <div className={style.textCode}>
                    #{code}
                </div>
            </div>
        </div>
    )
}
