import React, {useState, useEffect} from "react"
import{ alertService }from '../../_services/alert.service'
import { Container, Row, Card, CardBody } from "reactstrap"
import {passSocket} from '../../helpers/io';
const socket = passSocket();
import style from "./Login.module.css"

export default function Login({ history }) {

    const [code, setCode] = useState("");

    useEffect(() => {
        socket.on("H_studio_accepted", (code) => {
            history.push(`/${code}`);
        })
    }, [socket])

    function handleGameEnter() {
        console.log("ELO", code)
        if (!!code) {
            socket.emit("H_set_studio", code)
            // history.push(`/${code}`);
        }
        else alertService.error("Podaj kod gry");
    }

    return (
       <div className={style.container} >
           <div className={style.formContainer}>
                <form onSubmit={e => e.preventDefault()}>
                    <label htmlFor="gamecode">
                        <div className={style.formTitle}> Podaj kod gry </div>
                    </label>

                        <div className={style.buttonContainer}>
                            <input
                                className={style.formInput}
                                name="gamecode"
                                value={code}
                                onChange={(e) => {
                                    setCode(e.target.value)
                                }}
                            />
                        </div>
                        
                        <div className={style.buttonContainer}>
                            <button
                                className={style.formButton}
                                type="submit"
                                onClick={handleGameEnter}>
                                Wejdź do gry
                            </button>
                        </div>
                </form>
           </div>
        </div>
    )
}