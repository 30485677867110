import style from "./Question.module.css"
import { useState, useEffect } from "react";

export default function Question({ questionText }) {

    const [questionSize, setQuestionSize] = useState("3vw");

    return (
        <div className={ style.container } style={{ fontSize: questionSize }}>
            <div style={{margin: "1%" ,marginLeft: "10%", marginRight:"10%"}}>
                 {questionText}
            </div>
        </div>
    )
}