import React, { useEffect, useState, useRef } from "react";
import { Canvas, useThree } from "@react-three/fiber";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import * as THREE from "three";

const CameraController = ({ target, rotateSpeed }) => {

   const { camera, gl } = useThree();

   const [controls, setControls] = useState();

   useEffect(
      () => {
         const newControl = new OrbitControls(camera, gl.domElement);
         
         newControl.minDistance = 3;
         newControl.maxDistance = 40;
         newControl.target.y = 4;
         newControl.object.aspect = 1.55;
         newControl.object.position.set(-32, 8.23, -0.2);
         newControl.maxPolarAngle = Math.PI /2 - 0.1;
         newControl.enablePan = false;
         newControl.autoRotate = true;
         newControl.autoRotateSpeed = rotateSpeed ;
         newControl.update();

         setControls(newControl);

         setInterval(() => newControl.update(), 10)

         return () => {
           newControl.dispose();
         };
      },
      [camera, gl]
   );
   return null;
};

export default CameraController;