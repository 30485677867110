import React from "react";
import style from "./Counting.module.css";

export default function Counting({ counting }) {
    return (
        <div className={style.container}>
            <div className={style.text}>
                {counting}
            </div>
        </div>
    )
}

