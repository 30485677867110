import React, { useState, useEffect} from "react";
import style from "./QuestionPanel.module.css"
import Question from "./components/Question/Question";
import Answers from "./components/Answers/Answers";

const FADE_TIMEOUT = 1000;

export default function QuestionPanel({room_id, timeForAnswer, questionsBase, newGame}) {

    const [visibility, setVisibility] = useState("visible");
    const [questionText, setQuestionText] = useState("TEST");
    const [answersValues, setAnswersValues] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState(0);

    useEffect(() => {

        if(newGame) {
            setVisibility("visible");
            setCurrentQuestion(0);
        }

        showQuestion();

        const interval = setInterval(() => {
            showQuestion();
        }, timeForAnswer )

        return () => {
            clearInterval(interval);
        }
    }, []);

    function showQuestion() {
        if(getCurrentQuestion() < questionsBase.length){
            if(timeForAnswer > 0){
                questionFade();
            }
        } else {
            setVisibility("hidden");
            console.log("Koniec pytań");
        }
    }

    function getCurrentQuestion() {
        let support;
        setCurrentQuestion(current => {
            support = current;
            return current;
        })
        return support
    }

    function setNewVisibility() {
        switch (visibility) {
            case "visible":
                return style.visible;
            case "hidden":
                return style.hidden;
        }
    }

    function questionFade() {
        setVisibility("hidden");
        const questionTimeout = setTimeout(() => {
                setCurrentQuestion(current => current +1)
                setVisibility("visible");
            clearTimeout(questionTimeout);
        }, FADE_TIMEOUT)
    }

    return (
        <div className={ style.container+" "+setNewVisibility() }  >
            <div className={ style.element }>
                {
                  questionsBase[currentQuestion-1]?.content ? <Question questionText={questionsBase[currentQuestion-1].content} /> :<></>
                }
            </div>
            <div className={ style.element }>
                {
                    questionsBase[currentQuestion-1]?.answers ? <Answers answersValues={questionsBase[currentQuestion-1].answers} /> :<></>
                }
            </div>
        </div>
    )
}