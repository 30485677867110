import React from "react"
import Router from "./Router"

export default function App() {

  return (
    <div style={{backgroundColor:"white", height: '100%'}}>
        <Router />
    </div>
  )
}
