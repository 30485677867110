import React, { useState, useEffect } from "react"


import StudioContent from "./StudioContent"

import WaitingForStart from "../WaitingForStart/WaitingForStart"
import PlayersAndPoints from "../PlayersAndPoints/PlayersAndPoints"
import Game from "../QuestionPanel/Game"
import CodeEnter from "../CodeEnter/CodeEnter"

import {passSocket} from '../../helpers/io';
const socket = passSocket();

export default function StudioWrapper({ history }) {

    const [cameraTarget, setCameraTarget] = useState("main");
    const [code, setCode] = useState(null);
    const [showWaiting, setShowWaiting] = useState(false);
    const [questionsBase, setQuestionsBase] = useState([]);
    const [timeForAnswer, setTimeForAnswer] = useState(0);
    const [showPoints, setShowPoints] = useState(false);
    const [players, setPlayers] = useState();
    const [maxPoints, setMaxPoints] = useState(0);
    const [gameKey, setGameKey] = useState(100);  

    const [enableCameraRotation, setEnableCameraRotation] = useState(true);

    useEffect(() => {
      socket.on("H_send_code_to_studio", (code) => {
        console.log("KOD", code)
        setCode(code);
      })

      socket.on("H_game_data_to_studio", ({ code, questions, time }) => {
        console.log(questions);
        setMaxPoints(questions.length)
        setShowWaiting(false);
        setQuestionsBase(questions);
        setTimeForAnswer(time)
      })

      socket.on("H_players_points_to_studio", ({ players }) => {
        console.log("przesyła punkty")
        setPlayers(players);
        setShowPoints(true);
      })
    }, [socket])
  
    return (
      <div style={{backgroundColor:"black", height: '100%'}}>
      <StudioContent />
      {/* <ButtonsContainer setCameraTarget={setCameraTarget} setQuestionVisibility={setQuestionVisibility} /> */}
      {
        timeForAnswer > 0 ? 
            <Game key={gameKey} timeForAnswer={timeForAnswer} questionsBase={questionsBase} history={history} setEnableCameraRotation={setEnableCameraRotation} />            
        : <></>
      } 
      {
        showWaiting && <WaitingForStart key={gameKey+1} code={code} />
      }
      {
        showPoints && <PlayersAndPoints key={gameKey+2} history={history} players={players} maxPoints={maxPoints} setShowPoints={setShowPoints} setCode={setCode} />
      }
      { !code && <CodeEnter history={history} setTimeForAnswer={setTimeForAnswer} setShowWaiting={setShowWaiting} setGameKey={setGameKey} /> }
      </div>
    )
  }