import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Wrapper from "./components/Studio/Wrapper"
import Login from "./components/Login/Login"

export default function Router() {
    return (
        <BrowserRouter>
            <Switch>
            <Route exact path="/:id" component={Wrapper} />
                <Route exact path="/" component={Wrapper} />
                <Redirect from="*" to="/" />
            </Switch>
        </BrowserRouter>
    )
}