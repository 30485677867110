import style from "./Answers.module.css"
import Answer from "./Answer/Answer"

export default function Answers({ answersValues }) {
     return (
         <div className={ style.container}>
             <div className={ style.part }>
                <Answer value={answersValues[0]} color={"red"} />
                <Answer value={answersValues[1]} color={"blue"} />
             </div>
             <div className={ style.part }>
                <Answer value={answersValues[2]} color={"yellow"} />
                <Answer value={answersValues[3]} color={"green"} />
             </div>
         </div>
     )
}