import React, { Suspense, useState, useEffect, useRef } from "react"
import { Canvas, useFrame, extend,  useThree } from "@react-three/fiber"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

import Man from "./Showmaker";
import Studio from "./Studio";
import CameraController from "../Controls/OrbitControls";

extend({ OrbitControls });

export default function StudioContent() {

    return (
    <>
        <Canvas shadowMap >
            <ambientLight />
            <directionalLight position={[-5, 5, 5]} castShadow shadow-mapSize-width={1024} shadow-mapSize-height={1024} />
            <group position={[0, -1, 0]}>
            <Suspense fallback={null}>
                <CameraController rotateSpeed={0.3} />
                <Studio />
                <Man scale={[100,100,100]} />
            </Suspense>
            </group>
        </Canvas>
        <button onCLick={() =>  {  }} >
            ZMIEŃ TARGET
        </button>
    </>
    )
}
