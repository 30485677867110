import React, {useState, useEffect} from "react";
import QuestionPanel from "./QuestionPanel";
import Counting from "./components/Counting/Counting";

export default function Game({ timeForAnswer, questionsBase, history, setEnableCameraRotation }) {
    const [counting, setCounting] = useState(3);
    const [start, setStart] = useState(false);

    useEffect(() => {
        console.log(counting)
        let support;
        const interval = setInterval(() => {
            setCounting(c => {
                support = c-1;
                return c-1;
            });
            if(support < 0) {
                setStart(true);
                setEnableCameraRotation(false);
                clearInterval(interval);
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        }
    }, [])

    return (
        <>
            {
                start ?
                    <QuestionPanel timeForAnswer={timeForAnswer} questionsBase={questionsBase} room_id={history.location.pathname}/>
                    :
                    <Counting counting={counting} />
            }
        </>
    )
}