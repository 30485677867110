import React, {useEffect, useState} from "react";
import style from "./PlayersAndPoints.module.css"


export default function PlayersAndPoints({ history, players, maxPoints, setShowPoints, setCode}) {
    const [playersOnList, setPlayersOnList] = useState(players);
    console.log(playersOnList)

    useEffect(() => {
        playersOnList && playersOnList.sort(sortPlayersByPoints)
    }, [])

    function sortPlayersByPoints(a, b){
        if(a.points < b.points) {
            return 1;
        }
        if(a. points > b.points) {
            return -1;
        }
        return 0;
    }

    return (
        <div className={style.container}>
            <div className={style.table}>
                <div className={style.tableInside}>
                {
                    playersOnList ? playersOnList.map(player => {
                        return <PlayerOnList nr={playersOnList.indexOf(player)+1} nick={player.user.nick} points={player.points} maxPoints={maxPoints} />
                    }) :<></>
                }
                </div>
                <button className={style.button} onClick={() => {
                    setShowPoints(false);
                    setCode(null);
                    history.push(`/`);
                } }>
                    Powrót
                </button>
            </div>
        </div>
    )
}

function PlayerOnList({nr, nick, points, maxPoints}) {
    return (
        <div className={style.playerContainer} >
            <div className={style.nick}> {nr}. {nick} {nr == 1 && <img className={style.medalImage} src="https://cdn-icons-png.flaticon.com/512/744/744984.png" />  } </div>
            <div className={style.points}> {points}/{maxPoints} </div>
        </div>
    )
}   